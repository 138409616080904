export class CRBConfigDto {
  rId: number
  name: string
  usernamePrefix: string
  domain: string
  oktaCustomDomain: string
  programCode: string
}

export class CRBConfigSettingDto {
  rId: number
  keyName: string
  keyValue: string
  CRBConfigRId: number
}

export class CRBSettingKeys {
  // Colors
  static GlobalHeaderColor = 'globalHeaderColor'
  static CallToActionColor = 'callToActionColor'
  static TopNavigationBarColor = 'topNavigationBarColor'
  static ActiveNavFunctionColor = 'activeNavFunctionColor'
  static SecondaryFunctionColor = 'secondaryFunctionColor'
  static ModuleHeaderColor = 'moduleHeaderColor'
  static ModuleHeaderTextColor = 'moduleHeaderTextColor'
  static BottomNavigationColor = 'bottomNavigationColor'
  static SocialMediaIconColor = 'socialMediaIconColor'
  static DividerLineColor = 'dividerLineColor'
  static MainTextColor = 'mainTextColor'

  // Other Settings
  static BankSupportPhoneNumber = 'bankSupportPhoneNumber'
  static BankDisclosure = 'bankDisclosure'
  static IsTransferToBankEnabled = 'isTransferToBankEnabled'
  static IsOperatorSiteLinkEnabled  = 'isOperatorSiteLinkEnabled'
  static OperatorSiteLinkVerbiage = 'operatorSiteLinkVerbiage'
  static OperatorSiteLinkText = 'operatorSiteLinkText'
  static OperatorSiteLinkUrl = 'operatorSiteLinkUrl'
}

export const BrandColorKeys: string[] = [
  CRBSettingKeys.GlobalHeaderColor,
  CRBSettingKeys.CallToActionColor,
  CRBSettingKeys.TopNavigationBarColor,
  CRBSettingKeys.ActiveNavFunctionColor,
  CRBSettingKeys.SecondaryFunctionColor,
  CRBSettingKeys.ModuleHeaderColor,
  CRBSettingKeys.ModuleHeaderTextColor,
  CRBSettingKeys.BottomNavigationColor,
  CRBSettingKeys.SocialMediaIconColor,
  CRBSettingKeys.DividerLineColor,
  CRBSettingKeys.MainTextColor
]

